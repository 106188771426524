import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "@fortawesome/fontawesome-free/css/all.min.css"

import configs from "../../site-config"

const TermsAndConditions = ({ data }) => (
  <Layout>
    <SEO title="Home" keywords={configs.app_keywords} />

    <div
      className="imageWrapper"
     
    >
      <div className="headerBackground">
        <div className="container-terms">
          <header>
            <div className="logo">
              <div className="appIconShadow">
                <svg width="0" height="0">
                  <defs>
                    <clipPath id="shape">
                      <path
                        id="shape"
                        d="M6181.23,233.709v-1.792c0-.5-0.02-1-0.02-1.523a24.257,24.257,0,0,0-.28-3.3,11.207,11.207,0,0,0-1.04-3.132,10.683,10.683,0,0,0-1.95-2.679,10.384,10.384,0,0,0-2.68-1.943,10.806,10.806,0,0,0-3.13-1.038,19.588,19.588,0,0,0-3.3-.285c-0.5-.017-1-0.017-1.52-0.017h-22.39c-0.51,0-1.01.017-1.53,0.017a24.041,24.041,0,0,0-3.3.285,11.009,11.009,0,0,0-3.13,1.038,10.491,10.491,0,0,0-4.62,4.622,10.893,10.893,0,0,0-1.04,3.132,19.2,19.2,0,0,0-.28,3.3c-0.02.5-.02,1-0.02,1.523v22.392c0,0.5.02,1,.02,1.524a24.257,24.257,0,0,0,.28,3.3,10.9,10.9,0,0,0,1.04,3.132,10.491,10.491,0,0,0,4.62,4.622,11.04,11.04,0,0,0,3.13,1.038,19.891,19.891,0,0,0,3.3.285c0.51,0.017,1.01.017,1.53,0.017h22.39c0.5,0,1-.017,1.52-0.017a24.221,24.221,0,0,0,3.3-.285,10.836,10.836,0,0,0,3.13-1.038,10.408,10.408,0,0,0,2.68-1.943,10.683,10.683,0,0,0,1.95-2.679,11.217,11.217,0,0,0,1.04-3.132,20.257,20.257,0,0,0,.28-3.3c0.02-.5.02-1,0.02-1.524v-20.6h0Z"
                        transform="translate(-6131 -218)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <Img
                  fluid={data.headerIcon.childImageSharp.fluid}
                  className="headerIcon"
                />
              </div>
              <p className="headerName">{configs.app_name}</p>
            </div>
            <nav>
              <ul>
                {configs.presskit_download_link && (
                  <li>
                    <a href={configs.presskit_download_link}>Press Kit</a>
                  </li>
                )}
              </ul>
            </nav>
          </header>
          <div className="terms-and-conditions">
              <h1>Terms and conditions</h1>
              <br />
          The Food Barcode Scanner App is a individual project, created for Master's degree in UOC.<br />

Before registering on the Application, each User must carefully read this End User License Agreement (“EULA”) as well as the General Terms and Conditions and the rules regarding the Privacy Policy. The General Terms and Conditions form an integral part of this EULA. <br />

Use of the Food Barcode Scanner App assumes that the User has accepted this entire EULA, including the General Terms and Conditions. Such acceptance is deemed to be as valid as the User’s handwritten signature. <br />

To improve our app’s functionalities and services offered by analyzing disaggregated data not associated with a particular user’s personal information. <br />

<h2>Purpose</h2>

The main purpose of the App is to inform consumers about the ingredients of commonly sold food.<br />

<h2>Database</h2>
Food Barcode Scanner App does not guarantee that the Database and the exact composition of the analyzed products are totally reliable.<br />

The database is provided by Open Food Facts (openfoodfacts.org).<br />

<h2>Who is the personal data controller?</h2>

Sergio Durban as individual is the controller of the User’s personal data mentioned above<br />

<h2>What do We use your personal data for?</h2>
Please be informed that you only are required to provide personal data and information that is strictly necessary to fulfill the purposes explained below. In this regard, if you provide Us with more information than is expressly required, you consent to its processing for the purpose(s) for which it was granted. <br />

Having said that, We use your personal data for the following purposes: <br />

    To manage your information queries regarding our app and/or services offered through it; <br />

    To comply with our legal obligations, legal requirements, laws, and regulations and/or to respond to judicial, police or the corresponding authorities’ requirements; <br />

    To protect, investigate, and deter fraudulent, unauthorized, or illegal activity on/through our app; <br />

    To improve our app’s functionalities and services offered by analyzing disaggregated data not associated with a particular user’s personal information. <br />

    To show you advertisements related to our app while you are browsing your social media or third party websites. <br />

    To show you advertisements in our app <br />

<h2>What is the legal basis for processing your personal data?</h2>
We only process your personal data when there is a legal basis for doing so. The legal basis will depend on the reason(s) why We collect and process your personal data. In almost all cases the legal basis will be:<br />

    The consent that you provided Us with for the processing of your personal data in order to be able to provide you with the Services offered through the app and other purposes informed at the time of collection of your data. <br />

    The consent you have provided Apple and/or Google Play to install similar technologies on your device(s) for the purpose of tracking your activity in these platforms, for example, what applications you have installed or which information you share with third party partners about your browser or device for advertising purposes.<br />

    To comply with our legal obligations and/or to attend administrative, judicial, police, or the corresponding authorities’ official requirements.<br />

    Our legitimate interests in (i) offering an enhanced user experience when you access and use the app and operating it efficiently, (ii) monitoring fraudulent activity in order to preserve the security and integrity of our app; (iii) defending and addressing our rights in the event that a claim regarding our app and/or Services may arise.<br />

    <h2>How long do We keep your personal data for?</h2>

    We will keep your personal data only for as long as it is necessary to fulfill the purposes for which they are being processed and, beyond that, your personal data will be retained, and securely blocked, for the necessary retention period to meet our legal obligations and/or for our legitimate reasons (i.e to address claims in connection with the services offered through the app; to finish the investigation of detected fraudulent or illicit activity.) <br />

Regarding similar technologies used by Apple or Google Play to collect and store the information on your device that is later shared with the partners of the services offered by these platforms, we recommend that you consult the Privacy and Cookie Policies of these platforms for information on how to revoke the consent that you have given for the installation of similar technologies.<br />

Once the necessary retention period has ended, your personal data will be securely deleted from our information systems.<br />

Who do We share the User’s personal data with?<br />

We may communicate your personal data to the following third parties, including but not limited to:<br />

    (i) our main service providers, Apple and/or Google Play, where appropriate; <br />

    (ii) administrative, police or judicial authorities as long as the data is required in accordance with the applicable law and regulations; <br />

    (iii) law firms to respond to claims regarding our app or services offered through it; <br />

The third parties mentioned above may have their headquarters outside the European Economic Area (EEA), therefore, your personal data will be object of international transfers. In any event, if a personal data international transfer is deemed appropriate, We inform you that We will only transfer personal data to recipients located in countries that ensure an adequate level of data protection or, failing that, offer the appropriate safeguards required by the current applicable law and regulations on the protection of personal data, in order to ensure that your personal data is properly protected and that your individual rights and freedoms are guaranteed. In this regard, please be informed that the third parties mentioned above are committed with standard contractual clauses approved by the EU Commission certification in case of transfers to the USA.<br />

<h2>What rights do you have and how can you exercise them?</h2>

You may exercise your data protection rights (i.e access, erasure, etc) by written request addressed to our Company to the following email: info@scanfood.app. <br />

As a general rule, We will respond to data protection rights requests within one (1) month. Occasionally, this period may be extended by two (2) further months where necessary, taking into account whether your request is particularly complex or you have made several requests. In this case, We will notify you of this event and explain the reasons for the delay to you. <br />

Finally, We inform you that should you consider it appropriate, you have the right to lodge a complaint before the corresponding Supervisory Authority, especially if you consider that your data protection rights have not been adequately addressed. <br />

We remain at your disposal for any doubts or questions you may have about this Policy, please contact Us by email at info@scanfood.app. <br /><br />
            </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default TermsAndConditions

export const query = graphql`
  query {
    headerIcon: file(relativePath: { eq: "icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    appStore: file(relativePath: { eq: "appstore.png" }) {
      childImageSharp {
        fixed(width: 220) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    playStore: file(relativePath: { eq: "playstore.png" }) {
      childImageSharp {
        fixed(height: 75) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    iphoneScreen: file(relativePath: { glob: "screenshot/*.{png,jpg}" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    videoScreen: file(
      extension: { ne: "txt" }
      relativePath: { glob: "videos/*" }
    ) {
      publicURL
      extension
    }
    appIconLarge: file(relativePath: { eq: "icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 120) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    headerImage: file(relativePath: { eq: "headerimage.png" }) {
      childImageSharp {
        fluid(maxHeight: 714) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    iphonePreviewBlack: file(relativePath: { eq: "black.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    iphonePreviewBlue: file(relativePath: { eq: "blue.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    iphonePreviewCoral: file(relativePath: { eq: "coral.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    iphonePreviewWhite: file(relativePath: { eq: "white.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    iphonePreviewYellow: file(relativePath: { eq: "yellow.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
